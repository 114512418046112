var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"header d-flex align-center justify-space-between"},[_c('p',{staticClass:"mb-0 base-section-title"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.admin.view.user-profile"))+" ")]),_c('v-btn',{staticClass:"base-btn base-body-text",attrs:{"text":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('inline-svg',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/linear/arrow-left-1.svg')}}),_vm._v(" "+_vm._s(_vm.$t("shared.go-back"))+" ")],1)],1),_c('section',{staticClass:"profile-content mt-10"},[_c('div',{staticClass:"profile-content-body d-flex flex-column"},[_c('div',{staticClass:"profile-content-user-details base-card pa-0"},[_c('img',{staticClass:"profile-content-user-details-bg",attrs:{"alt":"bg","src":require('@/assets/images/profile-bg.png')}}),_c('div',{staticClass:"px-10 py-4 user-details d-flex flex-column flex-md-row"},[_c('div',[_c('ProfilePicture',{attrs:{"user":_vm.candidate.user_data}}),_c('div',{staticClass:"d-flex align-center mt-7"},[(
                  _vm.candidate.candidate_details.profile.personal_details.name
                )?_c('p',{staticClass:"mb-0 base-section-title user-name"},[(
                    _vm.candidate.candidate_details.profile.personal_details.name
                      .first_name
                  )?_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.candidate.candidate_details.profile.personal_details.name .first_name))+" ")]):_vm._e(),(
                    _vm.candidate.candidate_details.profile.personal_details.name
                      .middle_name
                  )?_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.candidate.candidate_details.profile.personal_details.name .first_name))+" ")]):_vm._e(),(
                    _vm.candidate.candidate_details.profile.personal_details.name
                      .last_name
                  )?_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.candidate.candidate_details.profile.personal_details.name .last_name))+" ")]):_vm._e()]):_c('p',{staticClass:"mb-0 base-section-title user-name"},[_vm._v(" "+_vm._s(_vm.candidate.user_data.first_name)+" "+_vm._s(_vm.candidate.user_data.last_name)+" ")]),_c('UserRating')],1),_c('UserDesignation',{attrs:{"user_profile":_vm.candidate.candidate_details.profile
                  ? _vm.candidate.candidate_details.profile
                  : null}}),_c('div',{staticClass:"mt-8 user-details-extra"},[_c('div',{staticClass:"details"},[_c('UserExperience',{attrs:{"profile":_vm.candidate.candidate_details}})],1)])],1),_c('div',{staticClass:"\n              mt-8 mt-md-0\n              ms-md-auto\n              d-flex\n              align-center\n              flex-column\n              justify-space-between\n            "},[_c('div',{staticClass:"d-flex align-center mb-2"},[_vm._l((_vm.candidate.user_data
                  .social_links),function(social_account,index){return [_c('v-btn',{key:index,style:(!social_account.url ? 'opacity: 0.6' : ''),attrs:{"href":!social_account.url.startsWith('http')
                      ? 'https://' + social_account.url
                      : social_account.url,"disabled":!social_account.url,"target":"_blank","icon":"","large":"","color":"primary"}},[_c('p',[_vm._v(_vm._s(social_account.link))]),_c('img',{staticClass:"user-details-social-icon",attrs:{"alt":social_account.platform,"src":_vm.social_icons[social_account.platform]}})])]})],2),(_vm.filter === _vm.ViewCandidatesOptions.PENDING)?[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"1rem"}},[_c('ApprovedDeclinedButton',{attrs:{"loading":_vm.loading,"large_btn":true,"text":_vm.$t('recruiter.admin.approvals.decline'),"view_type":_vm.CandidateCardViewOptions.declined},on:{"btn_action":function($event){return _vm.btn_action(false)}}}),_c('ApprovedDeclinedButton',{attrs:{"large_btn":true,"loading":_vm.loading,"text":_vm.$t('recruiter.admin.approvals.approve'),"view_type":_vm.CandidateCardViewOptions.approved},on:{"btn_action":function($event){return _vm.btn_action(true)}}})],1)]:_vm._e(),(_vm.filter === _vm.ViewCandidatesOptions.APPROVED)?[_c('p',{staticClass:"mb-0 base-body-text success--text"},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t("recruiter.admin.approvals.approved"))+" ")],1)]:_vm._e(),(_vm.filter === _vm.ViewCandidatesOptions.DECLINED)?[_c('p',{staticClass:"mb-0 base-body-text danger--text"},[_c('v-icon',{attrs:{"color":"danger"}},[_vm._v(" mdi-close-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t("recruiter.admin.approvals.declined"))+" ")],1)]:_vm._e(),_c('v-btn',{staticClass:"my-4 base-submit-button user-details-btn details-btn",attrs:{"disabled":!_vm.candidate.user_data.resume_uri,"href":_vm.candidate.user_data.resume_uri,"download":""}},[_c('inline-svg',{staticClass:"mr-3",attrs:{"transformSource":_vm.transform_resume_icon,"src":require('@/assets/icons/bold/document-text.svg')}}),_vm._v(" Resume ")],1)],2)])]),_c('HighlightsAwards',{attrs:{"highlights":_vm.candidate.candidate_details.profile &&
          _vm.candidate.candidate_details.profile.media &&
          _vm.candidate.candidate_details.profile.media.length > 0
            ? _vm.candidate.candidate_details.profile.media
            : []}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('EducationDetails',{attrs:{"education":_vm.candidate.candidate_details.profile &&
              Object.values(
                _vm.candidate.candidate_details.profile.education_detailed
              ).length > 0
                ? _vm.candidate.candidate_details.profile.education_detailed
                : null}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('CertificationDetails',{attrs:{"certifications":_vm.candidate.candidate_details.profile &&
              _vm.candidate.candidate_details.profile.certification_detailed &&
              Object.values(
                _vm.candidate.candidate_details.profile.certification_detailed
              ).length > 0
                ? _vm.candidate.candidate_details.profile.certification_detailed
                : {}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('WorkExperience',{attrs:{"work_details":_vm.candidate.candidate_details.profile &&
              Object.values(
                _vm.candidate.candidate_details.profile.work_history_detailed
              ).length > 0
                ? _vm.candidate.candidate_details.profile.work_history_detailed
                : {}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('Personality',{attrs:{"assessment_user_id":_vm.candidate.user_data.id,"personality_details":_vm.candidate.candidate_details.profile &&
              _vm.candidate.candidate_details.profile.personality_detailed &&
              Object.values(
                _vm.candidate.candidate_details.profile.personality_detailed
              ).length > 0
                ? _vm.candidate.candidate_details.profile.personality_detailed
                : null}})],1)],1),_c('SkillsWithGroups',{attrs:{"title":_vm.$t('shared.profile.skills-title'),"skills":_vm.candidate.candidate_details.skills,"allow_add":false,"assessment":_vm.candidate.candidate_details.assessment}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }